import { parseISO } from "date-fns";
import { useFormatter } from "next-intl";

export function useFormat() {
  const intl = useFormatter();
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  return {
    convertDate({ date, format }: { date: string; format?: object }): string {
      return intl.dateTime(parseISO(date), format || options);
    },
    relativeTime({ date, compareDate }: { date: string; compareDate?: Date }): string {
      return intl.relativeTime(parseISO(date), compareDate);
    }
  }
}