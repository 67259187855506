import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";

const LanguageSwitch = styled(Switch)(({ theme, small }) => ({
  width: small ? 38 : 62,
  height: small ? 24 : 34,
  padding: "6px 0px",
  "& .MuiSwitch-switchBase": {
    top: 0,
    margin: 0,
    padding: 0,
    left: "auto",
    transform: "translateX(0px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(14px)",
      "& .MuiSwitch-thumb": {
        backgroundImage: `url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' id='flag-icons-tr' viewBox='0 0 512 512'%3E%3Cg fill-rule='evenodd'%3E%3Cpath fill='%23e30a17' d='M0 0h512v512H0z'/%3E%3Cpath fill='%23fff' d='M348.8 264c0 70.6-58.3 127.9-130.1 127.9s-130.1-57.3-130.1-128 58.2-127.8 130-127.8S348.9 193.3 348.9 264z'/%3E%3Cpath fill='%23e30a17' d='M355.3 264c0 56.5-46.6 102.3-104.1 102.3s-104-45.8-104-102.3 46.5-102.3 104-102.3 104 45.8 104 102.3z'/%3E%3Cpath fill='%23fff' d='m374.1 204.2-1 47.3-44.2 12 43.5 15.5-1 43.3 28.3-33.8 42.9 14.8-24.8-36.3 30.2-36.1-46.4 12.8-27.5-39.5z'/%3E%3C/g%3E%3C/svg%3E%0A")`,
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundImage: `url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' id='flag-icons-gb' viewBox='0 0 512 512'%3E%3Cpath fill='%23012169' d='M0 0h512v512H0z'/%3E%3Cpath fill='%23FFF' d='M512 0v64L322 256l190 187v69h-67L254 324 68 512H0v-68l186-187L0 74V0h62l192 188L440 0z'/%3E%3Cpath fill='%23C8102E' d='m184 324 11 34L42 512H0v-3l184-185zm124-12 54 8 150 147v45L308 312zM512 0 320 196l-4-44L466 0h46zM0 1l193 189-59-8L0 49V1z'/%3E%3Cpath fill='%23FFF' d='M176 0v512h160V0H176zM0 176v160h512V176H0z'/%3E%3Cpath fill='%23C8102E' d='M0 208v96h512v-96H0zM208 0v512h96V0h-96z'/%3E%3C/svg%3E%0A")`,
    width: small ? 24 : 32,
    height:small ? 24 :  32,
    boxShadow: "none",
    "&:before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
    borderRadius: 20 / 2,
  },
}));

export default LanguageSwitch;
