import React from "react";
import Link from "next/link";
import Image from "next/image";

const MainBanner = () => (
  <div
    style={{
      display: "flex",
      top: 0,
      left: 0,
      justifyContent: "center",
      alignSelf: "center",
    }}
  >
    <Link href="/">
      <Image src="/logo_avs.png" alt="AVS Logo" width={70} height={65} />
    </Link>
  </div>
);

export default MainBanner;
