import firebase from "firebase/app";
import "firebase/messaging";
import localforage from "localforage";
import initAuth from "./initAuth";

initAuth();

const firebaseCloudMessaging = {
  init: async (AuthUser) => {
    if (firebase?.apps?.length) {
      // Initialize the Firebase app with the credentials
      try {
        const messaging = firebase.messaging();

        // Request the push notification permission from browser
        const status = await Notification.requestPermission();
        if (status && status === "granted") {
          // Get new token from Firebase
          const fcm_token = await messaging.getToken({
            vapidKey: "BD6ioDeT6wyQfhrrX070Tsxq5uWNSH-6mNlO-W5G_NTSeDye5bKwYkX-xVGZmjb1emI2tmrO7qt458u4lSOygC0",
          });

          const tokenInLocalForage = await localforage.getItem("fcm_token");

          if (tokenInLocalForage !== fcm_token) {
            localforage.setItem("fcm_token", fcm_token);

            const authToken = await AuthUser.getIdToken();
            const response = await fetch(`/api/notifications/fcmWebToken`, {
              method: "POST",
              headers: {
                Authorization: authToken || "unauthenticated",
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                webToken: fcm_token,
                uid: AuthUser.id,
              }),
            });

            return fcm_token;
          }

          // Set token in our local storage
          if (fcm_token) {
            localforage.setItem("fcm_token", fcm_token);
            return fcm_token;
          }
        }
      } catch (error) {
        console.error(error);
        return null;
      }
    }
  },
};
export { firebaseCloudMessaging };
