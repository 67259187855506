import { gql, useSubscription } from "@apollo/client";
import { GroupsSharp, NotificationsTwoTone } from "@mui/icons-material";
import { Badge, CircularProgress, ListItemIcon, Menu, MenuItem, Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import colors from "assets/theme/base/colors";
import SuiBox from "components/SuiBox";
import { useAuthUser } from "next-firebase-auth";
import { useNow, useTranslations } from "next-intl";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { atomToken, notificationAtom } from "utils/atom";
import { useFormat } from "utils/convertDate";
import getAbsoluteURL from "utils/getAbsoluteURL";

const Notifications = ({ email, isHR, isIT, isVM, isFM }) => {
  const AuthUser = useAuthUser();
  const { relativeTime } = useFormat();
  const [openMenu, setOpenMenu] = useState(null);
  const [notifications, setNotifications] = useRecoilState(notificationAtom);
  const [token, setToken] = useRecoilState(atomToken);

  useEffect(() => {
    async function fetchNotifications() {
      if (!token) {
        await AuthUser.getIdToken().then(async (token) => {
          setToken(token);
        });
      }
    }
    fetchNotifications();
  }, [token, AuthUser, setToken]);

  const NOTIFICATIONS_SUBSCRIPTION = gql`
    subscription ($user_uid: jsonb!, $limit: Int, $meta_type: jsonb) {
      notifications(
        order_by: { created_at: desc }
        where: {
          _and: [
            { uids: { _contains: $user_uid } }
            { meta_data: { _has_key: "type" } }
            { _not: { meta_data: { _contains: $meta_type } } }
          ]
        }
        limit: $limit
      ) {
        id
        title
        desc
        meta_data
        created_at
        view
      }
    }
  `;

  const { data, loading, error } = useSubscription(NOTIFICATIONS_SUBSCRIPTION, {
    variables: {
      user_uid: AuthUser.id,
      limit: 10,
      meta_type: { type: "channelaaa" },
    },
  });

  const notificationCountView = data?.notifications.filter((notification) => !notification.view[AuthUser.id]?.bool).length || 0;

  const handleOpenMenu = async ({ currentTarget }) => {
    setOpenMenu(currentTarget);
  };

  const handleCloseMenu = async ({ notifications }) => {
    notifications?.map(async (notification) => {
      if (!notification.view[AuthUser.id]) {
        const token = await AuthUser.getIdToken();
        const endpoint = getAbsoluteURL(`/api/notifications/update`);
        const response = await fetch(endpoint, {
          method: "POST",
          headers: {
            Authorization: token || "unauthenticated",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: notification.id,
          }),
        });

        setNotifications((prevNotifications) => {
          const updatedNotifications = prevNotifications.map((prevNotification) => ({
            ...prevNotification,
            view: {
              ...prevNotification.view,
              [AuthUser.id]: { bool: true },
            },
          }));
          return updatedNotifications;
        });
      }
    });
    setOpenMenu(null);
  };

  const now = useNow({
    updateInterval: 1000 * 10,
  });

  const getIcon = (type) => {
    switch (type) {
      case "team":
        return <GroupsSharp fontSize="medium" />;
      case "notification":
        return <NotificationsTwoTone fontSize="medium" />;
      default:
        return <NotificationsTwoTone fontSize="medium" />;
    }
  };

  const t = useTranslations("Notifications");
  return (
    <SuiBox display="inline-flex" mr={0}>
      <SuiBox>
        <Tooltip title={t("Management")}>
          <IconButton
            onClick={(e) => handleOpenMenu({ notifications, currentTarget: e.currentTarget })}
            size="large"
            color="primary"
            sx={{ mr: 1, p: 0 }}
            aria-controls={openMenu ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={openMenu ? "true" : undefined}
          >
            <NotificationsTwoTone sx={{ color: "#155489" }} />
            {notificationCountView > 0 ? (
              <Badge
                style={{
                  position: "absolute",
                  top: -2,
                  right: -2,
                  background: "#f44336",
                  borderRadius: "50%",
                  width: 14,
                  height: 14,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#fff",
                  fontSize: 10,
                }}
              >
                {notificationCountView}
              </Badge>
            ) : null}
          </IconButton>
        </Tooltip>
        <Menu
          anchorEl={openMenu}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          open={Boolean(openMenu)}
          onClose={() =>
            handleCloseMenu({
              notifications: data?.notifications,
            })
          }
        >
          {data?.notifications?.length > 0 ? (
            data?.notifications?.map((notification, index) => (
              <MenuItem
                key={index}
                onClick={() =>
                  handleCloseMenu({
                    notifications: data?.notifications,
                  })
                }
                size="small"
                circular
                sx={{
                  backgroundColor: notification.view[AuthUser.id]?.bool === true ? "transparent" : "#f5f5f5",
                }}
              >
                <ListItemIcon>{getIcon(notification.meta_data.type)}</ListItemIcon>
                <SuiBox display="flex" sx={{ flexDirection: "column" }}>
                  <SuiBox display="flex" alignItems="center" color={colors.text}>
                    {notification.title ? notification.title : notification.desc}
                  </SuiBox>
                  <SuiBox display="flex" fontSize={12} alignItems="center" alignSelf="flex-start" color={colors.text}>
                    {relativeTime({
                      date: notification.created_at,
                      dateCompare: now,
                    })}
                  </SuiBox>
                </SuiBox>
              </MenuItem>
            ))
          ) : loading ? (
            <MenuItem key={0} size="small" sx={{ display: "flex", justifyContent: "center" }} circular>
              <CircularProgress color="inherit" />
            </MenuItem>
          ) : (
            <MenuItem key={0} size="small" sx={{ display: "flex", justifyContent: "center" }} circular>
              {t("You have no notifications")}
            </MenuItem>
          )}
        </Menu>
      </SuiBox>
    </SuiBox>
  );
};

export default Notifications;
