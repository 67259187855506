/** @format */

import {
  AccountBalance,
  AdminPanelSettingsTwoTone,
  Campaign,
  RequestQuote,
  DirectionsCar,
  GroupsSharp,
  LocalOffer,
  PersonAdd,
  SupportAgent,
  Diversity3,
} from "@mui/icons-material";
import { Divider, ListItemIcon, Menu, MenuItem, Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import SuiBox from "components/SuiBox";
import { useTranslations } from "next-intl";
import Link from "next/link";
import { useState } from "react";

const Header = ({ email, isHR, isIT, isVM, isFM }) => {
  const [openMenu, setOpenMenu] = useState(null);

  const handleOpenMenu = ({ currentTarget }) => setOpenMenu(currentTarget);
  const handleCloseMenu = () => setOpenMenu(null);

  const t = useTranslations("Header");
  return (
    <SuiBox display={"inline-flex"} mr={0}>
      {email && (
        <SuiBox>
          <Tooltip title={t("Management")}>
            <IconButton
              onClick={handleOpenMenu}
              size="large"
              color="primary"
              sx={{ mr: 1, p: 0 }}
              aria-controls={openMenu ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openMenu ? "true" : undefined}
            >
              <AdminPanelSettingsTwoTone sx={{ color: "#155489" }} />
            </IconButton>
          </Tooltip>
          <Menu
            anchorEl={openMenu}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            open={Boolean(openMenu)}
            onClose={handleCloseMenu}
          >
            {isHR && (
              <>
                <Link href="/management/tickets" key={1}>
                  <MenuItem onClick={handleCloseMenu} size="small" circular>
                    <ListItemIcon>
                      <SupportAgent fontSize="small" />
                    </ListItemIcon>
                    {t("Ticket Management")}
                  </MenuItem>
                </Link>
                <Link href="/management/trainings" key={1}>
                  <MenuItem onClick={handleCloseMenu} size="small" circular>
                    <ListItemIcon>
                      <SupportAgent fontSize="small" />
                    </ListItemIcon>
                    {t("Training Management")}
                  </MenuItem>
                </Link>
              </>
            )}

            {isHR && <Divider key={20} />}
            {isHR && (
              <Link href="/management/announcements" key={21}>
                <MenuItem onClick={handleCloseMenu} size="small" circular>
                  <ListItemIcon>
                    <Campaign fontSize="small" />
                  </ListItemIcon>
                  {t("Announcement Management")}
                </MenuItem>
              </Link>
            )}
            {isHR && (
              <Link href="/management/users" key={22}>
                <MenuItem onClick={handleCloseMenu} size="small" circular>
                  <ListItemIcon>
                    <GroupsSharp fontSize="small" />
                  </ListItemIcon>
                  {t("User Management")}
                </MenuItem>
              </Link>
            )}
            {isHR && (
              <Link href="/management/users/create" key={23}>
                <MenuItem onClick={handleCloseMenu} size="small" circular>
                  <ListItemIcon>
                    <PersonAdd fontSize="small" />
                  </ListItemIcon>
                  {t("Create User")}
                </MenuItem>
              </Link>
            )}
            {isHR && (
              <Link href="/management/department" key={23}>
                <MenuItem onClick={handleCloseMenu} size="small" circular>
                  <ListItemIcon>
                    <Diversity3 fontSize="small" />
                  </ListItemIcon>
                  {t("Department Management")}
                </MenuItem>
              </Link>
            )}
            {isHR && (
              <Link href="/management/rooms" key={23}>
                <MenuItem onClick={handleCloseMenu} size="small" circular>
                  <ListItemIcon>
                    <MeetingRoomIcon fontSize="small" />
                  </ListItemIcon>
                  {t("Room Management")}
                </MenuItem>
              </Link>
            )}

            {isVM && <Divider key={30} />}
            {isVM && (
              <Link href="/management/vehicles" key={31}>
                <MenuItem onClick={handleCloseMenu} size="small" circular>
                  <ListItemIcon>
                    <DirectionsCar fontSize="small" />
                  </ListItemIcon>
                  {t("Vehicle Management")}
                </MenuItem>
              </Link>
            )}

            {isFM && <Divider key={40} />}
            {isFM && (
              <Link href="/management/expenses" key={41}>
                <MenuItem onClick={handleCloseMenu} size="small" circular>
                  <ListItemIcon>
                    <AccountBalance fontSize="small" />
                  </ListItemIcon>
                  {t("Expense Management")}
                </MenuItem>
              </Link>
            )}
            {isFM && (
              <Link href="/management/invoices" key={42}>
                <MenuItem onClick={handleCloseMenu} size="small" circular>
                  <ListItemIcon>
                    <AccountBalance fontSize="small" />
                  </ListItemIcon>
                  {t("Invoice Management")}
                </MenuItem>
              </Link>
            )}
            {isFM && (
              <Link href="/management/requests" key={39}>
                <MenuItem onClick={handleCloseMenu} size="small" circular>
                  <ListItemIcon>
                    <RequestQuote fontSize="small" />
                  </ListItemIcon>
                  {t("Requests")}
                </MenuItem>
              </Link>
            )}

            {isIT && <Divider key={50} />}
            {isIT && (
              <Link href="/management/equipments" key={51}>
                <MenuItem onClick={handleCloseMenu} size="small" circular>
                  <ListItemIcon>
                    <LocalOffer fontSize="small" />
                  </ListItemIcon>
                  {t("Equipment Management")}
                </MenuItem>
              </Link>
            )}
            {isIT && (
              <Link href="/management/tickets" key={1}>
                <MenuItem onClick={handleCloseMenu} size="small" circular>
                  <ListItemIcon>
                    <SupportAgent fontSize="small" />
                  </ListItemIcon>
                  {t("Ticket Management")}
                </MenuItem>
              </Link>
            )}
          </Menu>
        </SuiBox>
      )}
    </SuiBox>
  );
};

export default Header;
