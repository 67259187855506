/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import { Container, Grid } from "@mui/material";
import SuiTypography from "components/SuiTypography";
function BaseLayout({ children, stickyNavbar, login, color, header, title, description, illustration }) {
  return (
    <DashboardLayout>
      {!login ? (
        <SuiBox sx={{ p: 3 }}>
          <DashboardNavbar absolute={!stickyNavbar} />
          <Container disableGutters>
            <SuiBox mt={stickyNavbar ? 3 : 10}>{children}</SuiBox>
          </Container>
          <Footer />
        </SuiBox>
      ) : (
        <Grid container>
          <Grid item xs={11} sm={8} md={6} lg={4} xl={3} sx={{ mx: "auto" }}>
            <SuiBox display="flex" flexDirection="column" justifyContent="center" height="100vh">
              <SuiBox pt={3} px={3}>
                {!header ? (
                  <>
                    <SuiBox mb={1}>
                      <SuiTypography variant="h4" fontWeight="bold">
                        {title}
                      </SuiTypography>
                    </SuiBox>
                    <SuiTypography variant="body2" fontWeight="regular" color="text">
                      {description}
                    </SuiTypography>
                  </>
                ) : (
                  header
                )}
              </SuiBox>
              <SuiBox p={3}>{children}</SuiBox>
            </SuiBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <SuiBox
              display={{ xs: "none", lg: "flex" }}
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              width="calc(100% - 2rem)"
              height="calc(100vh - 2rem)"
              position="relative"
              borderRadius="lg"
              textAlign="center"
              bgColor={color}
              variant="gradient"
              m={2}
              px={13}
              sx={{ overflow: "hidden" }}
            >
              {illustration.pattern && (
                <SuiBox
                  component="img"
                  src={illustration.pattern.src}
                  alt="pattern-lines"
                  width="120rem"
                  position="absolute"
                  topl={0}
                  left={0}
                  opacity={0.4}
                />
              )}
              {illustration.image && (
                <SuiBox component="img" src={illustration.image.src} alt="chat-illustration" width="100%" maxWidth="31.25rem" />
              )}
              {illustration.title && (
                <SuiBox mt={6} mb={1}>
                  <SuiTypography variant="h4" color="black" fontWeight="bold">
                    {illustration.title}
                  </SuiTypography>
                </SuiBox>
              )}
              {illustration.description && (
                <SuiBox mb={1}>
                  <SuiTypography variant="body2" color="black">
                    {illustration.description}
                  </SuiTypography>
                </SuiBox>
              )}
            </SuiBox>
          </Grid>
        </Grid>
      )}
    </DashboardLayout>
  );
}

// Setting default values for the props of BaseLayout
BaseLayout.defaultProps = {
  stickyNavbar: false,
  menu: false,
  login: false,
  color: "info",
  header: "",
  title: "",
  description: "",
  illustration: {},
};

// Typechecking props for BaseLayout
BaseLayout.propTypes = {
  children: PropTypes.node.isRequired,
  stickyNavbar: PropTypes.bool,
  menu: PropTypes.bool,
  login: PropTypes.bool,
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  header: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string,
  illustration: PropTypes.shape({
    image: PropTypes.object,
    pattern: PropTypes.object,
    title: PropTypes.string,
    description: PropTypes.string,
  }),
};

export default BaseLayout;
