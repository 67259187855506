/**
  =========================================================
  * Soft UI Dashboard PRO React - v3.0.0
  =========================================================

  * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
  * Copyright 2021 Creative Tim (https://www.creative-tim.com)

  Coded by www.creative-tim.com

  =========================================================

  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
  */

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Icon from "@mui/material/Icon";
import Toolbar from "@mui/material/Toolbar";
import breakpoints from "assets/theme/base/breakpoints";
import DefaultNavbarMobile from "components/Navbars/DefaultNavbar/DefaultNavbarMobile";
import Header from "components/Navbars/Header";
import Management from "components/Navbars/Management";
import Notifications from "components/Navbars/Notifications";
import SingleNavbarLink from "components/Navbars/SingleNavbarLink";
import SuiTypography from "components/SuiTypography";
import { useAuthUser } from "next-firebase-auth";
import Link from "next/link";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useRecoilValueLoadable } from "recoil";
import { registeredTeamsSelector } from "utils/atom";
import MainBanner from "../../Banner";
// Custom styles for DashboardNavbar
import { navbar, navbarContainer, navbarRow } from "components/Navbars/DashboardNavbar/styles";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
// Soft UI Dashboard PRO React context
import LanguageSwitch from "components/LanguageSwitch";
import { setTransparentNavbar, useSoftUIController } from "context";
import { useRouter } from "next/router";
import { firebaseCloudMessaging } from "utils/firebase";
import { useSnackbar } from "notistack";
import "firebase/messaging";
import firebase from "firebase/app";

function DashboardNavbar({ absolute, transparent, light }) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useSoftUIController();
  const { transparentNavbar, fixedNavbar } = controller;
  const [mobileNavbar, setMobileNavbar] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [isVisible, setVisible] = useState(false);

  const { locale, locales, asPath } = useRouter();
  const otherLocale = locales?.find((cur) => cur !== locale);

  const AuthUser = useAuthUser();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (typeof window !== "undefined") {
      setToken();
    }
  }, []);

  // Calls the getMessage() function if the token is there
  async function setToken() {
    try {
      const token = await firebaseCloudMessaging.init(AuthUser);
      if (token) {
        getMessage();
      }
    } catch (error) {
      console.log(error);
    }
  }

  function getMessage() {
    const messaging = firebase.messaging();
    messaging.onMessage((message) => {
      enqueueSnackbar(
        <>
          <SuiTypography variant="h5" color="white">
            {message?.data?.title}
          </SuiTypography>
          <SuiTypography variant="subtitle2" color="white">
            {message?.data?.desc}
          </SuiTypography>
        </>,
        {
          autoHideDuration: 5000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        }
      );
      // const handleClickPushNotification = (url) => {
      //   window.open(url, "_blank");
      // }
      // const notificationTitle = message?.notification?.title;
      // const notificationOptions = {
      //   body: message?.notification?.body,
      //   icon: "/favicon.ico",
      //   onClick: () => handleClickPushNotification(message?.data?.url),
      // };
      // new Notification(notificationTitle, notificationOptions);
    });
  }

  const IsRegisteredTeams = () => {
    const registeredTeams = useRecoilValueLoadable(registeredTeamsSelector);
    switch (registeredTeams.state) {
      case "hasValue":
        return registeredTeams.contents.isVM ||
          registeredTeams.contents.isHR ||
          registeredTeams.contents.isIT ||
          registeredTeams.contents.isFM ? (
          <Management
            isVM={registeredTeams.contents.isVM}
            isHR={registeredTeams.contents.isHR}
            isIT={registeredTeams.contents.isIT}
            isFM={registeredTeams.contents.isFM}
            email={AuthUser.email}
          />
        ) : null;
      case "loading":
        return null;
      case "hasError":
        return null;
      default:
        return null;
    }
  };

  const openMobileNavbar = ({ currentTarget }) => setMobileNavbar(currentTarget.parentNode);
  const closeMobileNavbar = () => setMobileNavbar(false);

  useEffect(() => {
    // A function that sets the display state for the DefaultNavbarMobile.
    function displayMobileNavbar() {
      if (window.innerWidth < breakpoints.values.lg) {
        setMobileView(true);
        setMobileNavbar(false);
      } else {
        setMobileView(false);
        setMobileNavbar(false);
      }
    }

    /** 
       The event listener that's calling the displayMobileNavbar function when 
      resizing the window.
      */
    window.addEventListener("resize", displayMobileNavbar);

    // Call the displayMobileNavbar function to set the state with the initial value.
    displayMobileNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", displayMobileNavbar);
  }, []);

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /** 
       The event listener that's calling the handleTransparentNavbar function when 
      scrolling the window.
      */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const menuItemArray = [
    <SingleNavbarLink key={"Home"} name={"Home"} route="/" light={light} />,
    <SingleNavbarLink key={"Profile"} name={"Profile"} route="/profile" light={light} />,
    <SingleNavbarLink key={"Team"} name={"Team"} route="/team" light={light} />,
    <SingleNavbarLink key={"Calendar"} name={"Calendar"} route="/calendar" light={light} />,
    <SingleNavbarLink key={"Kanban"} name={"Kanban"} route="/kanban" light={light} />,
    <SingleNavbarLink key={"Messages"} name={"Messages"} route="/messages" light={light} />,
    <SingleNavbarLink key={"Trainings"} name={"Trainings"} route="/trainings" light={light} />,
  ];

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <SuiBox sx={{ display: "flex", alignSelf: "flex-start" }}>
          <MainBanner />
          <SuiBox
            sx={{ alignSelf: "center" }}
            // display={{ sm: "none", md: "flex" }}
            component={Link}
            href="/"
            py={transparent ? 1.5 : 0.75}
            lineHeight={1}
          >
            <SuiTypography
              variant="button"
              display={{ xs: "none", sm: "block" }}
              marginLeft={".5rem"}
              alignSelf="center"
              fontWeight="bold"
              color={light ? "white" : "dark"}
            >
              Business Management
            </SuiTypography>
          </SuiBox>
        </SuiBox>
        <SuiBox color="inherit" display={{ xs: "none", lg: "flex" }} m={0} p={0}>
          {/* Takımlar görünecek burada - Query string ile takımlara yönelndir veya menüde gözüksün takımlar - */}
          {menuItemArray.map((item) => item)}
          {/*  <SingleNavbarLink
              name={"database"}
              onClick={handleOpenMenu}
              closeHandler={handleCloseMenu}
              light={light}
              menu
            />
            <Menu
              anchorEl={openMenu}
              open={Boolean(openMenu)}
              onClose={handleCloseMenu}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <MenuItem onClick={handleCloseMenu} size="small" circular="true">
                <Link href="/db/vessel/list">{"List Vessels"}</Link>
              </MenuItem>
              <MenuItem onClick={handleCloseMenu} size="small" circular="true">
                <Link href="/db/location/list">{"List Locations"}</Link>
              </MenuItem>
            </Menu> */}
        </SuiBox>
        <SuiBox sx={(theme) => navbarRow(theme)} display={"inline-flex"}>
          <SuiBox
            sx={{
              alignSelf: "center",
              marginRight: { xs: 0, md: 3 },
            }}
          >
            <IsRegisteredTeams />
            <Notifications />
            <Link href={asPath} locale={otherLocale}>
              <LanguageSwitch sx={{ marginRight: 1 }} small checked={locale === "tr"} />
            </Link>
            <Header email={AuthUser.email} signOut={AuthUser.signOut} />
          </SuiBox>
        </SuiBox>
        <SuiBox
          display={{
            xs: "inline-block",
            lg: "none",
            position: "absolute",
            right: "1rem",
          }}
          lineHeight={0}
          py={1.5}
          pl={1.5}
          color="inherit"
          customClass="cursor-pointer"
          onClick={openMobileNavbar}
        >
          <Icon className="" fontSize="default">
            {mobileNavbar ? "close" : "menu"}
          </Icon>
        </SuiBox>
        {mobileView && <DefaultNavbarMobile menuItemArray={menuItemArray} open={mobileNavbar} close={closeMobileNavbar} />}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
};

export default DashboardNavbar;
