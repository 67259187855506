import { GroupAdd, Logout, Payments, Person, Support } from "@mui/icons-material";
import { Avatar, Divider, ListItemIcon, Menu, MenuItem, Tooltip } from "@mui/material";
import EventSeatIcon from '@mui/icons-material/EventSeat';
import IconButton from "@mui/material/IconButton";
import stringAvatar from "assets/theme/functions/stringAvatar";
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import { useTranslations } from "next-intl";
import Link from "next/link";
import { useState } from "react";

const Header = ({ email, signOut, light }) => {
  const [openMenu, setOpenMenu] = useState(null);

  const handleOpenMenu = ({ currentTarget }) => setOpenMenu(currentTarget);
  const handleCloseMenu = () => setOpenMenu(null);
  const t = useTranslations("Header");

  return (
    <SuiBox display={"inline-flex"} mr={1}>
      {email ? (
        <SuiBox>
          <Tooltip title={t("Account settings")}>
            <IconButton
              onClick={handleOpenMenu}
              size="small"
              sx={{ m: 0, p: 0 }}
              aria-controls={openMenu ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openMenu ? "true" : undefined}
            >
              <Avatar
                {...stringAvatar({
                  name: email,
                  width: 46,
                  height: 46,
                  fontSize: 16,
                })}
                size="sx"
              />
            </IconButton>
          </Tooltip>
          <Menu
            anchorEl={openMenu}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            open={Boolean(openMenu)}
            onClose={handleCloseMenu}
          >
            <Link href="/profile">
              <MenuItem onClick={handleCloseMenu} size="small" circular>
                <ListItemIcon>
                  <Person fontSize="small" />
                </ListItemIcon>
                {t("Profile")}
              </MenuItem>
            </Link>
            <Link href="/teams/create">
              <MenuItem onClick={handleCloseMenu} size="small" circular>
                <ListItemIcon>
                  <GroupAdd fontSize="small" />
                </ListItemIcon>
                {t("Create Team")}
              </MenuItem>
            </Link>
            <Divider />
            <Link href="/expenses">
              <MenuItem onClick={handleCloseMenu} size="small" circular>
                <ListItemIcon>
                  <Payments fontSize="small" />
                </ListItemIcon>
                {t("Expenses")}
              </MenuItem>
            </Link>
            <Link href="/invoices">
              <MenuItem onClick={handleCloseMenu} size="small" circular>
                <ListItemIcon>
                  <Payments fontSize="small" />
                </ListItemIcon>
                {t("Company Expenses")}
              </MenuItem>
            </Link>
            <Link href="/tickets">
              <MenuItem onClick={handleCloseMenu} size="small" circular>
                <ListItemIcon>
                  <Support fontSize="small" />
                </ListItemIcon>
                {t("Support Tickets")}
              </MenuItem>
            </Link>
            <Link href="/reservation">
              <MenuItem onClick={handleCloseMenu} size="small" circular>
                <ListItemIcon>
                  <EventSeatIcon fontSize="small" />
                </ListItemIcon>
                {t("Room Reservation")}
              </MenuItem>
            </Link>
            <Divider />
            <MenuItem onClick={() => handleCloseMenu && signOut()}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              {t("Logout")}
            </MenuItem>
          </Menu>
        </SuiBox>
      ) : (
        <Link href="/auth">
          <SuiButton size="small" variant="gradient" color="dark" sx={{ ml: 2 }}>
            {t("Sign In")}
          </SuiButton>
        </Link>
      )}
    </SuiBox>
  );
};

export default Header;
